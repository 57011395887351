
import './App.css'; // Adjust the path based on your file structure


import {
  useAddress,
  useNetwork,
  useContract,
  ConnectWallet,
  Web3Button,
  useNFTBalance,
} from '@thirdweb-dev/react';
import { ChainId } from '@thirdweb-dev/sdk';
import { useState, useEffect, useMemo } from 'react';
import { AddressZero } from "@ethersproject/constants";

<meta name="viewport" content="width=device-width, initial-scale=1"></meta>


const App = () => {
  // Use the hooks thirdweb give us.
  const address = useAddress();
  console.log("👋 Address:", address);

  const network = useNetwork();
  
  const [isMenuVisible, setMenuVisible] = useState(false);


  const [showLoginForm, setShowLoginForm] = useState(false);
  const [showRegisterForm, setShowRegisterForm] = useState(false);

  const [isLoginFormVisible, setIsLoginFormVisible] = useState(false);
  const [isRegisterFormVisible, setIsRegisterFormVisible] = useState(false);

    // Function to toggle the login form
    const handleLoginToggle = () => {
      setIsLoginFormVisible(!isLoginFormVisible);
      setIsRegisterFormVisible(false); // Close register form if open
    };
  
    // Function to toggle the register form
    const handleRegisterToggle = () => {
      setIsRegisterFormVisible(!isRegisterFormVisible);
      setIsLoginFormVisible(false); // Close login form if open
    };



// Toggle display of the login form
const toggleLoginForm = () => {
  setShowLoginForm(!showLoginForm);
  setShowRegisterForm(false); // Ensure only one form is visible at a time
};


// Toggle display of the registration form
const toggleRegisterForm = () => {
  setShowRegisterForm(!showRegisterForm);
  setShowLoginForm(false); // Ensure only one form is visible at a time
};


  // Style for the buttons
  const buttonStyle = {
    position: 'absolute', 
    top: 20, 
    backgroundColor: 'black', // Black background
    color: 'white', // White text
    textTransform: 'none', // Ensure text is lowercase
    border: 'none', // Remove border
    padding: '10px', // Add some padding
    cursor: 'pointer', // Change cursor on hover
  };

    // Inline styles for the buttons
    const buttonStyles = {
      backgroundColor: 'black', // Button background color
      color: 'white', // Button text color
      textTransform: 'none', // Text is lowercase
      border: 'none', // No border
      borderRadius: '5px', // Rounded corners
      padding: '10px 15px', // Padding inside the button
      cursor: 'pointer', // Cursor changes to pointer on hover
      outline: 'none', // Remove focus outline
      margin: '0 10px', // Space between buttons
    };


      // Container for buttons to align them at the top right
  const buttonContainerStyles = {
    position: 'absolute', // Position absolutely to place at the top right
    top: '0', // Align to the top of the container
    right: '0', // Align to the right of the container
    padding: '20px', // Padding around the container for spacing
    display: 'flex', // Use flexbox for layout
    alignItems: 'center', // Center items vertically
    justifyContent: 'flex-end', // Align items to the end (right) of the container
  };

  
 // Initialize our Edition Drop contract
 const editionDropAddress = "0xeCF9a987680ee41135FfA4C05AAb4A7C1703f188";
 const { contract: editionDrop } = useContract(editionDropAddress, "edition-drop");
 // Initialize our token contract
const { contract: token } = useContract("0xb14Fe8cd4C93DA35c1CA8d251aE9Be8aE7aDF123", "token");

const { contract: vote } = useContract("0x9087d5D61aA90BB515c7E6687C2E33267fB0D125", "vote");

 // Hook to check if the user has our NFT
 const { data: nftBalance } = useNFTBalance(editionDrop, address, "0")

 const hasClaimedNFT = useMemo(() => {
   return nftBalance && nftBalance.gt(0)
 }, [nftBalance])


// Holds the amount of token each member has in state.
const [memberTokenAmounts, setMemberTokenAmounts] = useState([]);
// The array holding all of our members addresses.
const [memberAddresses, setMemberAddresses] = useState([]);

// A fancy function to shorten someones wallet address, no need to show the whole thing.
const shortenAddress = (str) => {
  return str.substring(0, 6) + '...' + str.substring(str.length - 4);
};


const [proposals, setProposals] = useState([]);
const [isVoting, setIsVoting] = useState(false);
const [hasVoted, setHasVoted] = useState(false);

// Retrieve all our existing proposals from the contract.
useEffect(() => {
  if (!hasClaimedNFT) {
    return;
  }

  // A simple call to vote.getAll() to grab the proposals.
  const getAllProposals = async () => {
    try {
      const proposals = await vote.getAll();
      setProposals(proposals);
      console.log("🌈 Proposals:", proposals);
    } catch (error) {
      console.log("failed to get proposals", error);
    }
  };
  getAllProposals();
}, [hasClaimedNFT, vote]);

// We also need to check if the user already voted.
useEffect(() => {
  if (!hasClaimedNFT) {
    return;
  }

  // If we haven't finished retrieving the proposals from the useEffect above
  // then we can't check if the user voted yet!
  if (!proposals.length) {
    return;
  }


  const checkIfUserHasVoted = async () => {
    try {
      const hasVoted = await vote.hasVoted(proposals[0].proposalId, address);
      setHasVoted(hasVoted);
      if (hasVoted) {
        console.log("🥵 User has already voted");
      } else {
        console.log("🙂 User has not voted yet");
      }
    } catch (error) {
      console.error("Failed to check if wallet has voted", error);
    }
  };
  checkIfUserHasVoted();

}, [hasClaimedNFT, proposals, address, vote]);










// This useEffect grabs all the addresses of our members holding our NFT.
useEffect(() => {
  if (!hasClaimedNFT) {
    return;
  }

  // Just like we did in the 7-airdrop-token.js file! Grab the users who hold our NFT
  // with tokenId 0.
  const getAllAddresses = async () => {
    try {
      const memberAddresses = await editionDrop?.history.getAllClaimerAddresses(
        0,
      );
      setMemberAddresses(memberAddresses);
      console.log('🚀 Members addresses', memberAddresses);
    } catch (error) {
      console.error('failed to get member list', error);
    }
  };
  getAllAddresses();
}, [hasClaimedNFT, editionDrop?.history]);

// This useEffect grabs the # of token each member holds.
useEffect(() => {
  if (!hasClaimedNFT) {
    return;
  }

  const getAllBalances = async () => {
    try {
      const amounts = await token?.history.getAllHolderBalances();
      setMemberTokenAmounts(amounts);
      console.log('👜 Amounts', amounts);
    } catch (error) {
      console.error('failed to get member balances', error);
    }
  };
  getAllBalances();
}, [hasClaimedNFT, token?.history]);

// Now, we combine the memberAddresses and memberTokenAmounts into a single array
const memberList = useMemo(() => {
  return memberAddresses.map((address) => {
    // We're checking if we are finding the address in the memberTokenAmounts array.
    // If we are, we'll return the amount of token the user has.
    // Otherwise, return 0.
    const member = memberTokenAmounts?.find(({ holder }) => holder === address);

    return {
      address,
      tokenAmount: member?.balance.displayValue || '0',
    };
  });
}, [memberAddresses, memberTokenAmounts]);










 // ... include all your other code that was already there below.


  // This is the case where the user hasn't connected their wallet
  // to your web app. Let them call connectWallet.
  if (!address) {
    return (
      
      <div className="landing">
  
  <div className="btn-hero-unique">
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <a href="https://docs.google.com/forms/d/e/1FAIpQLSdFZ-xxHtne4bVIWIJjScW8DwbJjOrdUUcFxNRzxJOcOAJj8Q/viewform" target="_blank">
        <img src="https://i.imgur.com/ROSLOtL.png" alt="ARTedyC DAO 2 Logo" style={{ width: '200px', height: 'auto' }} />
    </a>
    <br></br>
    <br></br>
    <br></br>
</div>
        <h2>Welcome</h2>
       
        <h1>ARTedyX  DAO</h1>
        <h1> Re InsurBank </h1>
        <br></br>
  
        <h2 class="tagline">Re InsurBank: Securing and valorizing asset data through innovative ReFi CAT bond solutions.</h2>


        <br></br>
        <br></br>
      
        <div class="content">
        Empower Your Voice: 
        <br></br>Govern ARTedyX DAO with a Minimum Investment of €20. <br></br>
        Shape the Blockchain-Driven Climate Future. Engage Today
        <br></br>
        <br></br>
</div>

<div class="content-new">
    <div class="btn-hero-new">
        <a href="https://docs.google.com/forms/d/e/1FAIpQLSdFZ-xxHtne4bVIWIJjScW8DwbJjOrdUUcFxNRzxJOcOAJj8Q/viewform" target="_blank">
            <img src="https://i.imgur.com/1S1R81o.png" alt="ARTedyX DAO 1 Logo" />
        </a>
    </div>
    <div class="video-container-new">
        <iframe width="560" height="315" src="https://www.youtube.com/embed/K0ZqtZdZVFA" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
</div>
          <br></br>
          <br></br>

          <div class="content">
    and vote <br></br>
</div>
        <br></br>
        
       
      
        <div className="btn-hero">
          <ConnectWallet />
        </div>
        <br></br>
        <br></br>
      
        <div class="content">
    meet the team <br></br>
</div>
<br></br>
<br></br>
        <div className="btn-hero">
        
          <a href="https://climate-sat.com" target="_blank" class="button-style">CLIMATE-SAT </a>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>

          <div>
      <a href="https://insurbank.co" target="_blank" rel="noopener noreferrer">
        <img src="https://i.imgur.com/O5npfsX.png" alt="InsurBank Logo" style={{ width: '200px', height: 'auto' }} />
      </a>
    </div>

  
        
        </div>
        <br></br>
        <br></br>
        <div class="videos-container">
  <iframe class="video" src="https://www.youtube.com/embed/81_jjq1dZBU" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
  <iframe class="video" src="https://www.youtube.com/embed/hZE8ZLfZxmU" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
</div>
<br></br>
<br></br>


        <div class="welcome-text">
        Welcome to Re InsurBank, a pioneering venture within the ARTedyX DAO ecosystem. 
        <br></br>
        <br></br>
        We specialize in revolutionizing the insurance sector through innovative blockchain solutions. 
        <br></br>
        <br></br>
        At Re InsurBank, we harness the power of decentralized finance (DeFi) and regenerative finance (ReFi) to create resilient insurance products.
        <br></br>
        <br></br>
        Our platform leverages cutting-edge technologies to provide transparent, secure, and accessible insurance services to individuals and businesses worldwide. 
        <br></br>
        <br></br>
        As part of our mission, Re InsurBank transforms real-world assets monitored by satellites into digital assets traded on both centralized and decentralized platforms. 
        <br></br>
        <br></br>
        Our product offerings include ReFi CAT bond solutions, Exchange-Traded Products (ETPs), Spot Bitcoin ETFs, Spot Ethereum ETFs, and Spot ETCs. 
        <br></br>
        <br></br>
        Join us in shaping the future of re insurance with Re InsurBank CAT bond solutions.
        <br></br>
        <br></br>
        <h2 style={{ fontWeight: 'bold', marginBottom: '20px', color: 'green' }}>Digitizing Nature Through Satellite Technology</h2>


<p style={{ marginBottom: '20px' }}>At Re InsurBank we excel in transforming real-world assets into digital assets through state-of-the-art satellite monitoring technology. <br></br>
<br></br>
Focusing on sectors ranging from agriculture and climate to natural catastrophes, we leverage this advanced satellite monitoring to digitize and transform key assets into actionable digital assets.<br></br>
<br></br>
Our efforts span across diverse ecosystems, including forests, oceans, and atmospheric conditions, with the aim of bolstering sustainability and facilitating carbon credit trading within the ReFi ecosystem.  <br></br>
<br></br>
Despite facing challenges such as sensor limitations and data privacy concerns, we are at the forefront of developing innovative solutions to improve asset valuation and market integration. <br></br>
<br></br>
Our work adheres to global standards, enhancing transparency and trust in environmental asset classes, thereby propelling financial innovation towards a more sustainable future.</p>

<br></br>
<br></br>

        <div>
      <table className="table" style={{ borderCollapse: 'collapse', border: 'none' }}>
        <thead>
          <tr>
            <th className="tableHeader">Asset Type</th>
            <th className="tableHeader">Monitoring Metrics</th>
            <th className="tableHeader">Primary Applications</th>
            <th className="tableHeader">Challenges</th>
          </tr>
        </thead>
        <tbody>
          <tr className="forestRow">
            <td>Forests</td>
            <td>Tree cover, biomass, deforestation</td>
            <td>Carbon credit generation, biodiversity conservation</td>
            <td>Cloud cover, distinguishing species, illegal activities</td>
          </tr>

          <tr className="agricultureRow">
            <td>Agriculture</td>
            <td>Crop health, soil moisture</td>
            <td>Yield prediction, precision farming</td>
            <td>Seasonal variability, cloud cover interference</td>
          </tr>
          <tr className="climateRow">
            <td>Climate</td>
            <td>Temperature, precipitation patterns</td>
            <td>Climate change analysis, policy development</td>
            <td>Data complexity, long-term monitoring</td>
          </tr>
          <tr className="naturalCatastrophesRow">
            <td>Natural Catastrophes</td>
            <td>Storm tracking, flood extents</td>
            <td>Disaster response, insurance assessment</td>
            <td>Rapid response needs, distinguishing event types</td>
          </tr>

          <tr className="realEstateRow">
      <td>Real Estate</td>
      <td>Land use, infrastructure development</td>
      <td>Urban planning, investment analysis</td>
      <td>Resolution limitations, access to private data</td>
    </tr>
          {/* Repeat for other rows, using the appropriate class for each */}
                    <tr className="oceansRow">
            <td>Oceans</td>
            <td>Surface temperature, chlorophyll</td>
            <td>Fisheries management, pollution tracking</td>
            <td>Deep water monitoring, sensor calibration</td>
          </tr>
          <tr className="co2LevelsRow">
            <td>CO2 Levels</td>
            <td>Concentration in the atmosphere</td>
            <td>Climate modeling, regulatory compliance</td>
            <td>Atmospheric interference, calibration of sensors</td>
          </tr>
          <tr className="methaneRow">
            <td>Methane</td>
            <td>Emissions, hotspots</td>
            <td>Emission reduction initiatives, energy sector audits</td>
            <td>Detection sensitivity, distinguishing sources</td>
          </tr>
          <tr className="countriesRow">
            <td>Countries</td>
            <td>Land use, economic activity</td>
            <td>Policy making, economic analysis</td>
            <td>Political boundaries, data standardization</td>
          </tr>
          <tr className="continentsRow">
            <td>Continents</td>
            <td>Climate patterns, deforestation</td>
            <td>Environmental policy, continental scale analysis</td>
            <td>Scale of monitoring, integrating diverse data sources</td>
          </tr>
        </tbody>
      </table>
    </div>
    
    <br></br>
    <br></br>

    <div className="insurBankPresentation">
      <h2 className="presentationTitle">Transforming Fractional Ownership into Digital Assets for Sustainable Investing</h2>
      <p>At Re InsurBank ARTedyX DAO, we are pioneering the intersection of blockchain technology with fractional ownership of real-world assets, including land, forests, oceans, real estate, and environmental credits such as carbon dioxide (CO2) and methane quotas. Our mission is to democratize access to these valuable assets, traditionally the domain of insurance companies, pension funds, and banks, and to present them as digital assets and in Exchange-Traded Products (ETPs), Spot Bitcoin ETFs, Spot ETCs (Exchange-Traded Commodities), and Spot Ethereum ETFs.</p>
      {/* Each section is structured similarly, with titles given a "sectionTitle" class */}
      <p><strong className="sectionTitle">Real Estate and Land</strong>: Leveraging blockchain, Re InsurBank ARTedyX DAO enables fractional ownership in a diverse portfolio of real estate and land. From commercial and residential buildings to hotels and motels, our digital platform allows investors to own shares in properties across various locations and sectors, providing opportunities for significant returns and portfolio diversification..</p>
      <p><strong className="sectionTitle">Forests and Natural Resources</strong>: Our platform extends to the fractional ownership of forests and natural resources, turning these assets into digital shares. This not only offers financial returns but also supports sustainability goals like carbon sequestration, aligning investments with environmental benefits and acting as a hedge against inflation..</p>
      <p><strong className="sectionTitle">Oceans:</strong>: Recognizing the complexities and immense potential of the oceans, InsurBank ARTedyX DAO invests in marine-related industries and sustainable ocean resource use initiatives. Through blockchain, we facilitate investments in aquaculture and conservation projects, making a direct contribution to the health of our planet's oceans.</p>
      <p><strong className="sectionTitle">Environmental Credits:</strong>: We are at the forefront of investing in environmental credits, transforming CO2 and methane emission rights into tradable digital assets. This innovative approach allows investors to contribute to environmental sustainability while potentially benefiting from the burgeoning market for carbon offsets.</p>
      <p><strong className="sectionTitle">Commitment to ESG: </strong>: Our investment strategy is deeply rooted in ESG criteria, reflecting a commitment to not only financial returns but also the environmental and social impact of our portfolio. Through the fractional ownership and tokenization of real-world assets, InsurBank ARTedyX DAO empowers investors to participate in markets previously inaccessible due to high entry costs or regulatory barriers.</p>
     
     
      {/* Continue with similar structure for other paragraphs */}
      <p><strong className="sectionTitle">Blockchain as a Catalyst</strong>: By harnessing blockchain technology, Re InsurBank ARTedyX DAO is revolutionizing how fractional ownerships are managed, traded, and invested. Our platform ensures transparency, security, and efficiency, transforming these assets into digital tokens that can be easily traded or included in ETPs, Spot Bitcoin ETFs, Spot ETCs, and Spot Ethereum ETFs..</p>
      <p>Join us at Re InsurBank ARTedyX DAO in redefining investment in real-world assets through blockchain technology. Together, we can unlock the potential of these assets for all investors, contributing to a more sustainable and inclusive financial future.</p>
    </div>

    
</div>
<br></br>
<br></br>

<div className="comparisonTable">
      <table>
        <thead>
          <tr>
            <th>Aspect</th>
            <th>Traditional Approach</th>
            <th>Re InsurBank ARTedyX DAO Approach</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Assets</td>
            <td>Land, forests, oceans, real estate, environmental credits (CO2, methane quotas)</td>
            <td>Land, forests, oceans, real estate, environmental credits (transformed into digital assets)</td>
          </tr>
          <tr>
            <td>Ownership</td>
            <td>Fractional ownership managed through conventional financial structures</td>
            <td>Fractional ownership tokenized on the blockchain for enhanced accessibility and liquidity</td>
          </tr>
          <tr>
            <td>Investment Vehicle</td>
            <td>Direct investment, REITs, specialized funds</td>
            <td>Digital tokens, ETPs, Spot Bitcoin ETFs, Spot ETCs, Spot Ethereum ETFs</td>
          </tr>
          <tr>
            <td>Accessibility</td>
            <td>High entry barriers, often restricted to institutional investors</td>
            <td>Lowered entry barriers, accessible to a wider range of investors including retail</td>
          </tr>
          <tr>
            <td>Market Participation</td>
            <td>Limited by regulatory, logistical, and financial constraints</td>
            <td>Expanded through blockchain, enabling participation in previously inaccessible markets</td>
          </tr>
          <tr>
            <td>Sustainability</td>
            <td>Investments aligned with ESG criteria, but limited by traditional market mechanisms</td>
            <td>Direct contribution to sustainability through transparent, blockchain-enabled investments in environmental assets</td>
          </tr>
          <tr>
            <td>Transparency & Security</td>
            <td>Dependent on regulatory compliance and reporting standards</td>
            <td>Enhanced by blockchain's inherent transparency and security features</td>
          </tr>
          <tr>
            <td>Liquidity</td>
            <td>Generally low, due to the nature of real-world assets and investment structures</td>
            <td>Significantly increased through tokenization and the ability to trade digital shares on secondary markets</td>
          </tr>
          <tr>
            <td>Innovation in Investment</td>
            <td>Conventional methods with gradual integration of ESG criteria</td>
            <td>Pioneering the use of blockchain for investment in real-world assets, driving financial innovation</td>
          </tr>
        </tbody>
      </table>
    </div>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <h2 style={{ color: 'blue', textAlign: 'center', width: '100%' }}>Demo</h2>
    <br></br>
    <br></br>

    <div style={{ position: 'relative', top: '20%', left: '50%', transform: 'translateX(-50%)', zIndex: 1000 }}>

  <a href="https://eth-sat.com" target="_blank" rel="noopener noreferrer">
    <img src="https://i.imgur.com/4rSCiaA.png" alt="ETH-SAT Logo" style={{ width: '200px', height: 'auto' }} />
  </a>
  <div style={{ marginLeft: '20px', display: 'inline-block' }}>
    <iframe src="https://www.youtube.com/embed/DHTvydLc02w" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen style={{ width: '360px', height: '200px' }}></iframe>
  </div>
</div>


    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>


    <div>
      <h2 style={{ color: '#007bff', textAlign: 'center', marginBottom: '20px' }}>ARTedyX's Decentralized Autonomous Organization Partners  </h2>
      
      <div className="partners-container">
        {/* First Partner */}
        <div className="partner">
          <a href="https://eo-prometheus.space" target="_blank" rel="noopener noreferrer">
            <img src="https://i.imgur.com/dRNOo8g.png" alt="EO Prometheus" />
          </a>
        </div>

        {/* Second Partner */}
        <div className="partner">
          <a href="https://www.frankfurt-school.de/home/research/centres/blockchain" target="_blank" rel="noopener noreferrer">
            <img src="https://i.imgur.com/GRJxxX7.png" alt="Frankfurt School Blockchain Center" />
          </a>
        </div>
      </div>
    </div>


    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>


    <div>


  {/* Existing toggle menu button and menu code */}

  <button
    style={{ 
      color: 'white', 
      backgroundColor: 'black', 
      position: 'absolute', 
      top: 10, 
      left: 10, 
      zIndex: 100,
      textTransform: 'none',
      padding: '1px 3px', // Reduced padding around the button text

    }}
    onClick={() => setMenuVisible(!isMenuVisible)}
  >
    menu
    
  </button>


    

  <div style={{ position: 'absolute', top: 0, right: 0, zIndex: 1000 }}>
    <button
      style={{ backgroundColor: 'black', color: 'white', textTransform: 'none', marginRight: '1px', cursor: 'pointer' }}
      onClick={toggleLoginForm}
    >
      log in
    </button>
    <button
      style={{ backgroundColor: 'black', color: 'white', textTransform: 'none', cursor: 'pointer' }}
      onClick={toggleRegisterForm}
    >
      sign in
    </button>
</div>





      {showLoginForm && (
        <div
          style={{
            position: 'fixed',
            top: '50px',
            right: '10px',
            backgroundColor: 'grey',
            padding: '20px',
            borderRadius: '5px',
            textAlign: 'left',
          }}
        >
          <p style={{ color: 'white' }}> 🚀</p>
          {/* Insert your actual login form here */}
        </div>
      )}

      {showRegisterForm && (
        <div
          style={{
            position: 'fixed',
            top: '50px',
            right: '10px',
            backgroundColor: 'grey',
            padding: '20px',
            borderRadius: '5px',
            textAlign: 'left',
          }}
        >
          <p style={{ color: 'white' }}> 🚀</p>
          {/* Insert your actual register form here */}
        </div>
      )}
    </div>


    

  


    {isMenuVisible && (
  <div
    style={{
      position: 'absolute',
      top: '40px', // Position slightly below the button
      left: '10px',
      backgroundColor: 'grey', // Background color of the menu
      padding: '10px', // Padding inside the menu
      borderRadius: '5px', // Rounded corners of the menu
      width: '150px', // Width of the menu
    }}
  >
    <ul style={{ 
      listStyleType: 'none', 
      padding: 0,
      margin: 0, // Ensure no default margin
      textAlign: 'left', // Align text to the left
     }}>
      <li>
        <a 
          href="https://artedyx.com" 
          style={{ 
            color: 'white', 
            textDecoration: 'none',
            display: 'block', // Make the link fill the container
            padding: '5px 0', // Padding for the link
          }}
        >
          Home
        </a>
      </li>
      <li>
        <a 
          href="https://drive.google.com/file/d/1GR5jEe9dO0JzSo3QZLPRUybrjqk_ZfJV/view"  
          style={{
            color: 'white',
            textDecoration: 'none',
            display: 'block', // Ensure the link fills the container
            padding: '5px 0', // Padding for the link
          }} 
          target="_blank" 
          rel="noopener noreferrer"
        >
          White Paper
        </a>
      </li>
      <li>
        <a 
          href="https://taxbank.co"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            color: 'white',
            textDecoration: 'none',
            display: 'block', // Ensure the link fills the container
            padding: '5px 0', // Adjust padding as needed
          }}
        >
          TaxBank
        </a>
      </li>
      {/* New Bitcoin Page */}
      <li>
        <a 
          href="https://taxbank.co" // Replace with the actual URL of your Bitcoin page
          style={{
            color: 'white',
            textDecoration: 'none',
            display: 'block', // Ensure the link fills the container
            padding: '5px 0', // Padding for the link
          }}
        >
          Bitcoin
        </a>
      </li>
    </ul>
  </div>
)}





</div>

);
}

  
  
// If the user has already claimed their NFT we want to display the internal DAO page to them
// only DAO members will see this. Render all the members + token amounts.


if (hasClaimedNFT) {
  return (
    <div className="member-page">
      <h1>eSpatiale  🚀 ARTedyX DAO Member Page</h1>
      <p>Congratulations on being an eSpatiale  🚀 DAO governance member</p>
      <div>
        <div>
          <h2>Member List</h2>
          <table className="card">
            <thead>
              <tr>
                <th>Address</th>
                <th>Token Amount</th>
              </tr>
            </thead>
            <tbody>
              {memberList.map((member) => {
                return (
                  <tr key={member.address}>
                    <td>{shortenAddress(member.address)}</td>
                    <td>{member.tokenAmount}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div>
          <h2>Active Proposals</h2>
          <form
            onSubmit={async (e) => {
              e.preventDefault();
              e.stopPropagation();

              //before we do async things, we want to disable the button to prevent double clicks
              setIsVoting(true);

              // lets get the votes from the form for the values
              const votes = proposals.map((proposal) => {
                const voteResult = {
                  proposalId: proposal.proposalId,
                  //abstain by default
                  vote: 2,
                };
                proposal.votes.forEach((vote) => {
                  const elem = document.getElementById(
                    proposal.proposalId + '-' + vote.type,
                  );

                  if (elem.checked) {
                    voteResult.vote = vote.type;
                    return;
                  }
                });
                return voteResult;
              });

              // first we need to make sure the user delegates their token to vote
              try {
                //we'll check if the wallet still needs to delegate their tokens before they can vote
                const delegation = await token.getDelegationOf(address);
                // if the delegation is the 0x0 address that means they have not delegated their governance tokens yet
                if (delegation === AddressZero) {
                  //if they haven't delegated their tokens yet, we'll have them delegate them before voting
                  await token.delegateTo(address);
                }
                // then we need to vote on the proposals
                try {
                  await Promise.all(
                    votes.map(async ({ proposalId, vote: _vote }) => {
                      // before voting we first need to check whether the proposal is open for voting
                      // we first need to get the latest state of the proposal
                      const proposal = await vote.get(proposalId);
                      // then we check if the proposal is open for voting (state === 1 means it is open)
                      if (proposal.state === 1) {
                        // if it is open for voting, we'll vote on it
                        return vote.vote(proposalId, _vote);
                      }
                      // if the proposal is not open for voting we just return nothing, letting us continue
                      return;
                    }),
                  );
                  try {
                    // if any of the propsals are ready to be executed we'll need to execute them
                    // a proposal is ready to be executed if it is in state 4
                    await Promise.all(
                      votes.map(async ({ proposalId }) => {
                        // we'll first get the latest state of the proposal again, since we may have just voted before
                        const proposal = await vote.get(proposalId);

                        //if the state is in state 4 (meaning that it is ready to be executed), we'll execute the proposal
                        if (proposal.state === 4) {
                          return vote.execute(proposalId);
                        }
                      }),
                    );
                    // if we get here that means we successfully voted, so let's set the "hasVoted" state to true
                    setHasVoted(true);
                    // and log out a success message
                    console.log('successfully voted');
                  } catch (err) {
                    console.error('failed to execute votes', err);
                  }
                } catch (err) {
                  console.error('failed to vote', err);
                }
              } catch (err) {
                console.error('failed to delegate tokens');
              } finally {
                // in *either* case we need to set the isVoting state to false to enable the button again
                setIsVoting(false);
              }
            }}
          >
            {proposals.map((proposal) => (
              <div key={proposal.proposalId} className="card">
                <h5>{proposal.description}</h5>
                <div>
                  {proposal.votes.map(({ type, label }) => (
                    <div key={type}>
                      <input
                        type="radio"
                        id={proposal.proposalId + '-' + type}
                        name={proposal.proposalId}
                        value={type}
                        //default the "abstain" vote to checked
                        defaultChecked={type === 2}
                      />
                      <label htmlFor={proposal.proposalId + '-' + type}>
                        {label}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            ))}
            <button disabled={isVoting || hasVoted} type="submit">
              {isVoting
                ? 'Voting...'
                : hasVoted
                ? 'You Already Voted'
                : 'Submit Votes'}
            </button>
            {!hasVoted && (
              <small>
                This will trigger multiple transactions that you will need to
                sign.
              </small>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}

// Render mint nft screen.
return (
  <div className="mint-nft">
    <h1>Mint your free eSpatiale 🚀 DAO governance Membership NFT</h1>
    <div className="btn-hero">
      <Web3Button
        contractAddress={editionDropAddress}
        action={(contract) => {
          contract.erc1155.claim(0, 1);
        }}
        onSuccess={() => {
          console.log(
            `🌊 Successfully Minted! Check it out on OpenSea: https://testnets.opensea.io/assets/${editionDrop.getAddress()}/0`,
          );
        }}
        onError={(error) => {
          console.error('Failed to mint NFT', error);
        }}
      >
        Mint youreSpatiale 🚀 NFT for (FREE)
      </Web3Button>
    </div>
  </div>
);
};

export default App;
